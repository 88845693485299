import React from 'react';
import Contacts from '../Components/ContactUs/ContactUs';

const ContactUs = () => {
  return (
    <div>
        <Contacts/>
    </div>
  )
}

export default ContactUs