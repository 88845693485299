import { useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Product } from './Pages/Product';
import Shop from './Pages/Shop';
import ContactUs from './Pages/ContactUs';
import { ProductPage } from './Pages/ProductPage';
import { Cart } from './Pages/Cart';
import { LoginSignup } from './Pages/LoginSignup';
import Footer from './Components/Footer/Footer';
import men_banner from './Components/Assets/banner_mens.png';
import PlaceOrder from './Components/PlaceOrder/PlaceOrder';
import OrderModal from './Components/PlaceOrder/OrderModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Scroll to top function
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path='/' element={<Product />} />
            <Route path='/shop' element={<Shop banner={men_banner} />} />
            <Route path='/contacts' element={<ContactUs />} />
            <Route path='/product' element={<ProductPage />}>
              <Route path=":productId" element={<ProductPage />} />
            </Route>
            <Route path='/cart' element={<Cart />} />
            <Route path='/login' element={<LoginSignup />} />
            <Route path='/order' element={<PlaceOrder />} />
            <Route path='/ordersuccess' element={<OrderModal />} />
          </Routes>
          <Footer />
        </BrowserRouter>

        <ToastContainer
          style={{ fontSize: '16px', marginTop: 65 }}
          position="top-center"
          autoClose={1000}
          closeOnClick
          pauseOnHover
          containerId="GlobalApplicationToast"
        />
      </div>
    </>
  );
}

export default App;