import React from 'react'
import Hero from '../Components/Hero/Hero'
import Popular from '../Components/Popular/Popular'
import { NewPrdtCollections } from '../Components/NewPrdtCollection/NewPrdtCollections'
import Gazebo from '../Components/GazeboBanner/Gazebo'
import FeatherBanner from '../Components/FeatherBanner/FeatherBanner'
import Footer from '../Components/Footer/Footer'

export const Product = () => {
  return (
    <div>
      <Hero/>
      <Popular/>
      {/* <NewPrdtCollections/> */}
      <Gazebo/>
      <FeatherBanner/>
    </div>
  )
}
