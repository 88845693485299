import React from "react";
import '../Footer/Footer.css'
import { HiPlus } from "react-icons/hi";
import {AiOutlineCopyrightCircle} from "react-icons/ai"
import { Link } from "react-router-dom";
import { BsInstagram, BsFacebook } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='footerWrap'>
      <div className='footer'>
        <div>
          <p className='FH'>High quality display stands/Materials</p>
          <p className='first2'>
            High Canvas Enterprise is a leading provider <br /> of high-quality roll-up banners and other <br /> display materials for businesses <br /> and individuals in Nigeria. <br />
            With a commitment to exceptional <br /> customer service and a passion for <br /> helping their clients succeed, 
          </p>
        </div>
        <div>
          <p className='FH1'> QUICK LINKS</p>
        <Link to='/shop' style={{textDecoration:'none'}}> <p className='second24'>Shop</p> </Link> 
         <Link to='/contacts' style={{textDecoration:'none'}}><p className='second24'>Contact Us</p> </Link> 
        <Link to='/login' style={{textDecoration:'none'}}><p className='second24'>My Account</p> </Link>  
          <p className='second24'>Terms and Condition</p>
          <p className='second24'>Privacy Policy</p>
        </div>
        <div>
          <p className='FH1'>Contact Us </p>
          <p className='second2'>
            37 Shipeolu, Shomolu, Lagos
          </p>
          {/* <div className='inputH'>
            <input
              type="text"
              className='input'
              placeholder="Enter Email Address"
            />
            <span className='HiP'>
              <HiPlus color="fff" cursor="pointer" />
            </span>
          </div> */}
        </div>
      </div>
      <div className='footerB'>
      <div className='footer_social_handles_container'>
                <div className='footer_social_handle'>
                    <a href="https://www.instagram.com/high_canvas.print?igsh=OGQ5ZDc2ODk2ZA==" className='footer_social_handle_insta'>
                        <BsInstagram style={{ fontSize: 18, marginRight: '5px' }} />
                        <p className='footer_social_handle_text'>Instagram</p>
                    </a>

                    <a href="/" className='footer_social_handle_facebook'>
                        <BsFacebook style={{ fontSize: 18, marginRight: '5px' }} />
                        <p className='footer_social_handle_text'>Facebook</p>
                    </a>
                </div>
            </div>
        <p className='second2'>+2349095342206</p>
        <p className='second24'><AiOutlineCopyrightCircle fontSize={14} />2024 All Rights Reserved</p>
        <p className='underLine'>Highcanvas01@gmail.com</p>
      </div>
    </div>
  );
};

export default Footer;
