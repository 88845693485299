import React, { useContext, useState, useEffect } from 'react';
import '../Pages/CSS/Shop.css';
import { ShopContext } from '../Context/ShopContext';
import { RiSearch2Line } from "react-icons/ri";
import Item from '../Components/Item/Item';
import { TailSpin } from 'react-loader-spinner';

export default function Shop() {
  const { all_product } = useContext(ShopContext);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [displayedProducts, setDisplayedProducts] = useState(12); // Initially display 12 products
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to handle search
  const handleSearch = () => {
    setDisplayedProducts(12); // Reset displayed products when searching
    setLoading(true); // Start loading
    setTimeout(() => { // Simulate a delay for the loader
      const filtered = all_product.filter(product => {
        return (
          product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.price.toString().includes(searchTerm)
        );
      });
      setFilteredProducts(filtered);
      setLoading(false); // Stop loading
    }, 500); // Delay in milliseconds
  };

  // Function to handle category change
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setDisplayedProducts(12); // Reset displayed products when category changes
  };

  // Function to handle "Explore More" button click
  const handleExploreMore = () => {
    setDisplayedProducts(displayedProducts + 12); // Incrementally display more products
  };

  // Determine the array to display based on whether there's a search term
  const productsToDisplay = searchTerm ? filteredProducts : (selectedCategory ? all_product.filter(item => item.category === selectedCategory) : all_product);

  // Slice the products to display based on the number of displayed products
  const slicedProducts = productsToDisplay.slice(0, displayedProducts);

  return (
    <div className='product-category'>
      {/* Search Input */}
      <div className="hero-search">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch}><RiSearch2Line /></button>
      </div>
      <div className="productcategory-indexsort">
        <p>
          <span>Showing 1-{slicedProducts.length}</span> Out of {productsToDisplay.length} products
        </p>
        <div>
          {/* Sort by */}
          <select className="productCategory-sort" value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Sort Products</option>
            <option value="popular product">Popular Product</option>
            <option value="gazebo">Gazebo canopy tent</option>
            <option value="popup">Popup</option>
            <option value="feather">Feather flag banner</option>
            <option value="teardrop">Teardrop</option>
            <option value="blockbanner">Block banner</option>
            <option value="flagpole">Flagpole</option>
            <option value="fliers">Fliers</option>
            <option value="Xbanner">X banner</option>
            <option value="rollup">Rollup</option>
            <option value="promotionaltable">Promotional Table or Sampling stand</option>
            <option value="lanterbanner/stand">Lantern Banner/Stand</option>
            <option value="jombo">Jombo Stand</option>
            <option value="props">Props</option>
            <option value="dummycheque">Dummy cheque</option>
            <option value="instagramframe">Instagram Frame</option>
            <option value="placard">Placards</option>
            <option value="popuptable">Popup Table</option>
            <option value="abshandfan">Abs Handfan</option>
            <option value="spin">Spin</option>
            <option value="drawstring bags">Drawstring bags</option>
            <option value="carrierbag">Carrier Bags</option>
            <option value="bunties">Bunties</option>
            <option value="dangler">Dangler</option>
            <option value="shielftrips">Shielftrips</option>
            <option value="wallcanvas">Wall Canvas</option>
          </select>
        </div>
      </div>
      <div className="productCategory-items">
        {loading ? (
          <div className="loader">
            <TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="loading"
            />
          </div>
        ) : (
          slicedProducts.map((item, i) => (
            <Item key={i} id={item.id} name={item.name} image={item.image} price={item.price} />
          ))
        )}
      </div>
      {displayedProducts < productsToDisplay.length && !loading && ( // Render "Explore More" button only if there are more products to display and not loading
        <div className="productCategory-loadmore" onClick={handleExploreMore}>
          Explore More
        </div>
      )}
    </div>
  );
}
