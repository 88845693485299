import React, { useContext } from 'react';
import '../ProductDisplay/ProductDisplay.css';
import { ShopContext } from '../../Context/ShopContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProductDisplay = (props) => {
  const { product } = props;
  const { addToCart } = useContext(ShopContext);

  const handleAddToCart = () => {
    addToCart(product.id);
    toast.success(`${product.name} added to cart!`, {
      toastId: `toast-success-${product.id}`,
      containerId: 'GlobalApplicationToast',
    });
  };

  return (
    <div className="productdisplay">
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
          <img src={product?.image} alt="" />
          <img src={product?.image} alt="" />
        </div>
        <div className="productdisplay-img">
          <img className='productdisplay-main-img' src={product?.image} alt="" />
        </div>
      </div>
      <div className="productdisplay-right">
        <h1>{product?.name}</h1>
        <div className="productdisplay-right-prices-con">
          <div className="productdisplay-right-price">₦{product?.price}</div>
        </div>
        <div className="productdisplay-right-description">
          To order, simply send your own <span>{product?.name}</span> to email: Highcanvas01@gmail.com.com or request for design help from our team. We will print and ship to your doorstep anywhere within Nigeria.
        </div>
        <Link to='/cart'>
          <button onClick={handleAddToCart}>ADD TO CART</button>
        </Link>
        <p className='productdisplay-right-category'><span>Category : <span>{product?.category}</span></span></p>
      </div>
    </div>
  );
}

export default ProductDisplay;
