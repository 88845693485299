import React from 'react'
import CartItems from '../Context/CartItems/CartItems'


export const Cart = () => {
  return (
    <div>
      <CartItems />
    </div>
  )
}
