import img1 from '../Assets/3_3 m gazebo.jpg'
import img2 from '../Assets/4 by 3 m gazebo.jpg'
import img3 from '../Assets/Adjustable backdrop(Popup banner) .jpg'
import img4 from '../Assets/Feather teardrop banner .jpg'
import img5 from '../Assets/Jumbo backdrop display stand .jpg'
import img6 from '../Assets/Jumbo backdrop display.jpg'
import img7 from '../Assets/Pop up table exhibition .jpg'
import img8 from '../Assets/Popup display stand .jpg'
import img9 from '../Assets/Popup table .jpg'
import img10 from '../Assets/Promotional  table .jpg'
import img11 from '../Assets/feather banners.jpg'
import img12 from '../Assets/general-branding.jpg'

let data_product = [
  {
    id: 1,
    name: "3 by 3 m gazebo",
    price: "25,000",
    image: img1,
  },
  {
    id: 2,
    name: "4 by 3 m gazebo",
    price: "15,000",
    image: img2
  },
  {
    id: 3,
    name: "Adjustable backdrop(Popup banner)",
    price: "30,000",
    image: img3
  },
  {
    id: 4,
    name: "Feather teardrop banner",
    price: "40,000",
    image: img4
  },
  {
    id: 5,
    name: "Jumbo backdrop display stand ",
    price: "10,000",
    image: img5
  },
  {
    id: 6,
    name: "Jumbo backdrop display",
    price: "25,000",
    image: img6
  },
  {
    id: 7,
    name: "Pop up table exhibition ",
    price: "37,000",
    image: img7
  },
  {
    id: 8,
    name: "Popup display stand",
    price: "20,000",
    image: img8
  },
  {
    id: 9,
    name: "Popup table",
    price: "25,000",
    image: img9
  },
  {
    id: 10,
    name: "Promotional  table",
    price: "25,000",
    image: img10
  },
  {
    id: 11,
    name: "feather banners",
    price: "25,000",
    image: img11
  },
  {
    id: 12,
    name: "general-branding",
    price: "25,000",
    image: img12
  }
];

export default data_product;
