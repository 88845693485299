import React from 'react';
import '../DescriptionBox/DescriptionBox.css'

const DescriptionBox = () => {
  return (
    <div className="descriptionbox">
        <div className="descriptionbox-navigator">
            {/* <div className="descriptionbox-nav-box">
                Description
            </div> */}
            {/* <div className="descriptionbox-nav-box fade">Reviews (122)</div> */}
        </div>
        <div className="descriptionbox-description">
            <p>To design and print roll-up banners, follow these steps:</p>

        <li>Determine the size of your banner: Roll-up banners come in different sizes, so it’s essential to determine the size you need before designing. The most common size for roll up banners is 33 inches wide by 80 inches tall</li>
        <li>Choose a design software: You can use design software such as Adobe Photoshop or Illustrator, or use online design tools like Canva or Piktochart</li>
        <li>Create your design: Use your chosen design software to create your banner design. Keep the design simple and eye-catching, with bold and readable text and high-quality images</li>
        <li>Choose your material: Roll-up banners can be printed on different materials such as vinyl, fabric, or paper. Vinyl is the most popular material for roll-up banners as it’s durable and can withstand wear and tear</li>
        <li>Print your banner: Once you have your design and material, it’s time to print your banner. You can print your banner using a local printer or an online printing service</li>
        <li>Assemble your roll-up banner: After printing, you will need to assemble your roll-up banner. Most roll-up banners come with a stand that you can easily set up</li>
        <li>Display your banner: Now that your banner is assembled, you can display it at your event or location. Make sure to place it in a visible and high-traffic area to get maximum exposure</li>
        <li>Remember to proofread your roll-up banners before printing and ensure that all the details are correct. A well-designed and professionally printed roll-up banner can help you attract more customers and promote your brand effectively</li>
        </div>
    </div>
  )
}

export default DescriptionBox