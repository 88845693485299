import React from 'react';
import '../PlaceOrder/OrderModal.css'
import { Link } from 'react-router-dom';


const OrderModal = () => {
  return (
    <div className="modal">
    <div className="modal-content">
      <h2>Thanks for shopping with us</h2>
      <p>Your payment was successful.</p>
      <Link style={{ textDecoration: 'none' }} to='/'><button>Continue Shopping</button></Link> 
    </div>
  </div>
  )
}

export default OrderModal