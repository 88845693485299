import React, { useContext, useState, useEffect } from 'react';
import '../Item/Item.css';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import { toast } from 'react-toastify';

const Item = (props) => {
  const { addToCart, getTotalCartAmount } = useContext(ShopContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  const handleAddToCart = () => {
    addToCart(props.id);
    getTotalCartAmount();
    toast.success(`${props.name} added to cart!`, {
      toastId: `toast-success-${props.id}`,
      containerId: 'GlobalApplicationToast',
    });
  };

  return (
    <div className='item'>
      <Link to={`/product/${props.id}`}>
        {loading ? (
          <div className="skeleton-wrapper">
            <div className="skeleton-img"></div>
          </div>
        ) : (
          <img onClick={window.scrollTo(0, 0)} src={props.image} alt="" style={{ width: '100%', height: 'auto' }} />
        )}
      </Link>
      <div className="item_text">
        <p>{loading ? <div className="skeleton-text"></div> : props.name}</p>
      </div>
      <div className="item-price-con">
        <div className="item-prices">
          {loading ? (
            <div className="skeleton-text"></div>
          ) : (
            `₦${props.price}`
          )}
        </div>
      </div>
      <button
        className="add-to-cart-button"
        onClick={handleAddToCart}
        disabled={loading}
      >
        {loading ? 'Adding to Cart...' : 'Add to Cart'}
      </button>
    </div>
  );
};

export default Item;