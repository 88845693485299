import React, { createContext, useEffect, useState } from "react";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
    let cart = {};
    for (let index = 0; index < 300 + 1; index++) {
        cart[index] = 0;
    }
    return cart;
}

const ShopContextProvider = (props) => {

    const [all_product, setAll_Product] = useState([]);
    const [cartItems, setCartItems] = useState(getDefaultCart());

    useEffect(() => {
        // Fetch all products
        fetch('https://high-canvas-backend.onrender.com/allproducts')
            .then((response) => response.json())
            .then((data) => setAll_Product(data))
            .catch((error) => console.error("Error fetching products:", error));

        // Fetch the user's cart if authenticated
        const token = localStorage.getItem('auth-token');
        if (token) {
            fetch('https://high-canvas-backend.onrender.com/getcart', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`, // Use Authorization header with Bearer scheme
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            })
            .then((response) => response.json())
            .then((data)=>console.log(data));
            
        }
    }, []);

    const addToCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));

        const token = localStorage.getItem('auth-token');
        if (token) {
            fetch('https://high-canvas-backend.onrender.com/addtocart', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`, // Use Authorization header with Bearer scheme
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ itemId }),
            })
            .then((response) => response.json())
            .then((data)=>console.log(data));
        }
    }

    const removeFromCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));

        const token = localStorage.getItem('auth-token');
        if (token) {
            fetch('https://high-canvas-backend.onrender.com/removefromcart', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`, // Use Authorization header with Bearer scheme
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ itemId }),
            })
            .then((response) => response.json())
            .then((data)=>console.log(data));
        }
    }

    const getTotalCartAmount = () => {
        let totalAmount = 0;
        for (const item in cartItems) {
            if (cartItems[item] > 0) {
                let itemInfo = all_product.find((product) => product.id === Number(item));
                if (itemInfo) {
                    totalAmount += itemInfo.price * cartItems[item];
                }
            }
        }
        return totalAmount;
    }

    const getTotalCartItems = () => {
        let totalItem = 0;
        for (const item in cartItems) {
            if (cartItems[item] > 0) {
                totalItem += parseInt(cartItems[item]);
            }
        }
        return totalItem;
    }

    const clearCart = () => {
        setCartItems(getDefaultCart());
    }

    const contextValue = {
        getTotalCartItems,
        getTotalCartAmount,
        all_product,
        cartItems,
        addToCart,
        removeFromCart,
        clearCart
    };

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    )
}

export default ShopContextProvider;