import React, { useEffect, useState } from 'react';
import '../GazeboBanner/Gazebo.css';
import Item from '../Item/Item';
import { TailSpin } from 'react-loader-spinner'; // Import the loader

const Gazebo = () => {
  const [gazeboProduct, setGazeboProduct] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetch('https://high-canvas-backend.onrender.com/gazebo')
      .then((response) => response.json())
      .then((data) => {
        setGazeboProduct(data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <TailSpin
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <div className='gazebo_banner'>
      <h1>Gazebo Canopy Tent</h1>
      <hr />
      <div className="gazebo">
        {gazeboProduct.map((item, i) => (
          <Item key={i} id={item.id} name={item.name} image={item.image} price={item.price} />
        ))}
      </div>
    </div>
  );
}

export default Gazebo;
