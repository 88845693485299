import React, { useState, useContext } from 'react';
import '../../Components/Hero/Hero.css';
import Marquee from '../Hero/  Marquee/Marquee';
import { RiSearch2Line } from "react-icons/ri";
import arrow_icon from '../Assets/arrow.png';
import hero_img from '../Assets/High_Canvas_Branding_New.png';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import Item from '../Item/Item';

const Hero = () => {
  const { all_product } = useContext(ShopContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Filter products based on search term
  const handleSearch = () => {
    const filtered = all_product.filter(product => {
      return (
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.price.toString().includes(searchTerm)
      );
    });
    setFilteredProducts(filtered);
  };

  return (
    <>
      <Marquee />
      <div className='hero'>
        <div className="hero-left">
          <h2>QUALITY DISPLAY STANDS BRANDING AND PRODUCTION</h2>
          <div>
            <div className="hero-hand-icon">
              <p>shipped to your door step</p>
              {/* <img src={hand_icon} alt="" /> */}
            </div>
            {/* Search Input */}
          </div>
          <div className="hero-search">
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button onClick={handleSearch}><RiSearch2Line /></button>
          </div>

          <div className="hero-latest-btn">
            <Link to='/shop' style={{ textDecoration: 'none' }}> <div style={{ color: 'white' }}>Shop now</div> </Link>
            <img src={arrow_icon} alt="arrow_icon" />
          </div>
        </div>
        <div className="hero-right">
          <img src={hero_img} alt="hero_img" style={{ width: '100%', height: 'auto' }} />
        </div>
      </div>

      {/* Display Filtered Products */}
      <div className="filtered-products">
        {filteredProducts.map((product, i) => (
           <Item key={i} id={product.id} name={product.name} image={product.image} price={product.price}/> 
        ))}
      </div>
    </>
  );
}

export default Hero;
