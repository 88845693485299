import React from "react";
import { GoDotFill } from "react-icons/go";
import styles from "./styles.module.css";

const Marquee = () => {
  return (
    <div className={styles.marqueeH}>
      <marquee className={styles.marquee}>
            WE DELIVER QUALITY DISPLAY STANDS BRANDING AND PRODUCTION{" "}
        <GoDotFill className={styles.marqueeI} />
        WE DELIVER QUALITY DISPLAY STANDS BRANDING AND PRODUCTION{" "}
        <GoDotFill className={styles.marqueeI} />
        WE DELIVER QUALITY DISPLAY STANDS BRANDING AND PRODUCTION{" "}
        <GoDotFill className={styles.marqueeI} />
        WE DELIVER QUALITY DISPLAY STANDS BRANDING AND PRODUCTION{" "}
        <GoDotFill className={styles.marqueeI} />
        WE DELIVER QUALITY DISPLAY STANDS BRANDING AND PRODUCTION{" "}
        <GoDotFill className={styles.marqueeI} />
        WE DELIVER QUALITY DISPLAY STANDS BRANDING AND PRODUCTION{" "}
        <GoDotFill className={styles.marqueeI} />
      </marquee>
    </div>
  );
};

export default Marquee;
