import React, { useContext, useState } from 'react';
import './CartItems.css';
import { ShopContext } from '../ShopContext';
import remove_icon from '../../Components/Assets/cart_cross_icon.png';
import { useNavigate } from "react-router-dom";

const CartItems = () => {
    const { getTotalCartAmount, all_product, cartItems, removeFromCart, addToCart } = useContext(ShopContext);
    const navigate = useNavigate();

    // State for selected delivery option
    const [selectedOption, setSelectedOption] = useState(null); // No option selected initially

    // Function to handle change in delivery option
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    // Function to handle increment of quantity
    const handleIncrement = (itemId) => {
        addToCart(itemId);
    };

    // Function to handle decrement of quantity
    const handleDecrement = (itemId) => {
        removeFromCart(itemId);
    };

    // Check if the cart is empty
    const isCartEmpty = Object.values(cartItems).every(quantity => quantity === 0);

    // Calculate the total with or without delivery fee
    const calculateTotal = () => {
        const cartTotal = getTotalCartAmount();
        if (selectedOption === 'withinLagos') {
            return cartTotal + 5000;
        } else if (selectedOption === 'outsideLagos') {
            return cartTotal + 10000;
        }
        return cartTotal; // No delivery option selected
    };

    // Function to handle the navigation with state
    const handleCheckout = () => {
        const totalAmount = calculateTotal();
        navigate('/order', { state: { totalAmount, selectedOption } });
    };

    return (
        <div className="cartitems">
            <div className="cartitems-format-main">
                <p>Products</p>
                <p>Title</p>
                <p>Price</p>
                <p>Quantity</p>
                <p>Total</p>
                <p>Remove</p>
            </div>
            <hr />
            {all_product.map((e) => {
                if (cartItems[e.id] > 0) {
                    return (
                        <div key={e.id}>
                            <div className="cartitems-format cartitems-format-main">
                                <img src={e.image} alt="" className='carticon-product-icon' />
                                <p>{e.name}</p>
                                <p>₦{e.price}</p>
                                <div className='cartitems-quantity-con'>
                                    <button onClick={() => handleDecrement(e.id)}>-</button>
                                    <span className="cartitems-quantity">{cartItems[e.id]}</span>
                                    <button onClick={() => handleIncrement(e.id)}>+</button>
                                </div>
                                <p>₦{e.price * cartItems[e.id]}</p>
                                <img className='cartitems-remove-icon' src={remove_icon} onClick={() => { removeFromCart(e.id) }} alt="" />
                            </div>
                            <hr />
                        </div>
                    );
                }
                return null;
            })}
            <div className="cartitems-down">
                <div className="cartitems-total">
                    <h1>Cart Totals</h1>
                    <div>
                        <div className="cartitems-total-item">
                            <p>Subtotal</p>
                            <p>₦{getTotalCartAmount()}</p>
                        </div>
                        <hr />
                        {!isCartEmpty && (
                            <>
                                <div className="cartitems-total-item">
                                    {/* Radio buttons for delivery options */}
                                    <label>
                                        <input
                                            type="radio"
                                            value="withinLagos"
                                            checked={selectedOption === 'withinLagos'}
                                            onChange={() => handleOptionChange('withinLagos')}
                                        />
                                        Delivery Within Lagos (₦5000)
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="outsideLagos"
                                            checked={selectedOption === 'outsideLagos'}
                                            onChange={() => handleOptionChange('outsideLagos')}
                                        />
                                        Delivery Outside Lagos (₦10000)
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="none"
                                            checked={selectedOption === null}
                                            onChange={() => handleOptionChange(null)}
                                        />
                                        No Delivery
                                    </label>
                                </div>
                                <hr />
                            </>
                        )}
                        <div className='cartitems-total-item'>
                            <h3>Total</h3>
                            <h3>₦{calculateTotal()}</h3>
                        </div>
                    </div>
                    <button onClick={handleCheckout}>PROCEED TO CHECKOUT</button>
                </div>
            </div>
        </div>
    )
}

export default CartItems;