import React, { useEffect, useState } from 'react';
import '../NewPrdtCollection/NewPrdtCollections.css';
// import New_collection from '../Assets/new_collections'
import Item from '../Item/Item';

export const NewPrdtCollections = () => {

  const [new_product,setNew_product] = useState([]);

  useEffect(()=>{
    fetch('https://high-canvas-backend.onrender.com/newproducts')
    .then((response)=>response.json())
    .then((data)=>setNew_product(data))
  },[])

  return (
    <div className='New_collections'>
      <h1>New Products</h1>
      <hr />
      <div className="collections">
        {new_product.map((item,i)=>{
          return <Item key={i} id={item.id} name={item.name} image={item.image} price={item.price}/>
        })}
      </div>
    </div>
  )
}
