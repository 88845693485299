import React, { useContext, useState} from 'react';
import { ShopContext } from '../../Context/ShopContext';
import { useLocation, useNavigate } from 'react-router-dom'; 
import '../PlaceOrder/PlaceOrder.css';
import PaystackPop from '@paystack/inline-js';

const PlaceOrder = () => {
    const navigate = useNavigate(); 
    const { cartItems, all_product, clearCart } = useContext(ShopContext);
    
    const location = useLocation(); // Access the state passed via navigate
    const { totalAmount, selectedOption } = location.state || {}; // Get totalAmount and selectedOption from state

    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState('');

    // Function to validate phone number
    const validatePhoneNumber = (number) => {
        const phoneRegex = /^(?:\+234|0)[789]\d{9}$/; // Nigerian phone number format
        return phoneRegex.test(number);
    };

    // Handle change and validation
    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        setPhone(newPhone);

        if (validatePhoneNumber(newPhone)) {
            setPhoneError('');
        } else {
            setPhoneError('Please enter a valid phone number.');
        }
    };

    const sendOrderDetails = async () => {
        const selectedProductIds = Object.keys(cartItems).filter(itemId => cartItems[itemId] > 0);
        const selectedProducts = all_product.filter(product => selectedProductIds.includes(String(product.id)));

        const orderDetails = {
            email,
            firstName,
            lastName,
            phone,
            cartItems: selectedProducts.map(product => ({
                name: product.name,
                quantity: cartItems[String(product.id)]
            })),
            totalAmount,
            address,
            deliveryOption: selectedOption,
        };

        try {
            const response = await fetch('https://formspree.io/f/xjvnnrqq', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderDetails)
            });

            if (response.ok) {
                console.log('Order details sent successfully');
                clearCart();
                navigate('/ordersuccess');
            } else {
                console.error('Error sending order details');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const payWithPaystack = (e) => {
        e.preventDefault();

        const paystack = new PaystackPop();
        paystack.newTransaction({
            key: "pk_live_24133b28e05e943c7b423aa654d9657253e48fac",
            amount: totalAmount * 100,
            email,
            first_name: firstName, 
            last_name: lastName,
            phone,
            metadata: {
                custom_fields: [
                    {
                        display_name: "Address",
                        variable_name: "address",
                        value: address
                    }
                ]
            },
            callback: (response) => {
                console.log("Payment Successful:", response);
                sendOrderDetails();
                setEmail("");
                setAddress("");
                setFirstName("");
                setLastName("");
                setPhone("");
            },
            onClose: () => {
                console.log("Payment Closed");
            }
        });
    };

    return (
        <form className='place-order'>
            <div className="place-order-left">
                <p className="title">Delivery Information</p>
                <div className="multi-fields">
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        placeholder='First Name'
                    />
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        placeholder='Last Name'
                    />
                </div>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder='Email Address'
                />
                <div className="multi-fields">
                <input
                type="text"
                value={phone}
                onChange={handlePhoneChange}
                required
                placeholder='Phone Number'
            />
            {phoneError && <p style={{ color: 'red' }}>{phoneError}</p>}
                </div>
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder='Address'
                />
            </div>
            <div className="place-order-right">
                <div className="cartitems-total">
                    <h1>Cart Totals</h1>
                    <div>
                        <div className="cartitems-total-item">
                            <h3>Total</h3>
                            <h3>₦{totalAmount}</h3> {/* Display the total amount passed from CartItems */}
                        </div>
                    </div>
                    <button onClick={payWithPaystack}>PROCEED TO PAYMENT</button>
                </div>
            </div>
        </form>
    );
}

export default PlaceOrder;
