import React from 'react';
import '../ContactUs/ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <h2>You are welcome to walk into our office</h2>
      <p className="address">
      37 Shipeolu, Shomolu, Lagos
        Nigeria.
      </p>
      <p className="contact-number">Contact number: +2349095342206</p>
      <div className="map-container">
        <iframe
          title="Map"
          width="600"
          height="450"
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3963.868003053317!2d3.3658218738645114!3d6.53834872299293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sng!4v1722332690996!5m2!1sen!2sng"
        ></iframe>
      </div>
      <form className="contact-form" action="https://formspree.io/f/mkgwrozg" method="POST">
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" required />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email*</label>
        <input type="email" id="email" name="email" required />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message*</label>
        <textarea id="message" name="message" required />
      </div>
      <button type="submit">Submit</button>
    </form>
    </div>
  );
};

export default ContactUs;
