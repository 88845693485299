import React, { useEffect, useState } from 'react'
import '../Popular/Popular.css'
// import data_product from '../Assets/Item_data';
import Item from '../Item/Item'

const Popular = () => {

  const [popularProduct,setPopularProduct] = useState([]);

  useEffect(()=>{
    fetch('https://high-canvas-backend.onrender.com/popularproduct')
    .then((response)=>response.json())
    .then((data)=>setPopularProduct(data))
  },[])

  return (
    <div className='popular'>
        <h1>POPULAR PRODUCT</h1>
        <hr />
        <div className="popular_item">
            {popularProduct.map((item,i)=>{
                return <Item key={i} id={item.id} name={item.name} image={item.image} price={item.price}/> 
            })}
        </div>
    </div>
  )
}

export default Popular