import React, { useContext, useState } from 'react'
import './Navbar.css'
import logo from '../Assets/highcanvaslogo.png'
import cart_icon from '../Assets/cart_icon.png'
import { BsInstagram, BsFacebook } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import ProductCategory from '../ProductCategories/ProductCategory';

export default function Navbar(props) {
    const {product} = props;
    const [menu, setMenu] = useState("home");
    const [showMenu, setShowMenu] = useState(false); // State to control menu visibility
    const { getTotalCartItems } = useContext(ShopContext);

    // Function to toggle menu visibility
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
      setShowMenu(false);
  };

    return (
        <>
            <div className='social_handles_container'>
                <div className='social_handle'>
                    <a href="https://www.instagram.com/high_canvas.print?igsh=OGQ5ZDc2ODk2ZA==" className='social_handle_insta'>
                        <BsInstagram style={{ fontSize: 18, marginRight: '5px' }} />
                        <p className='social_handle_text'>Instagram</p>
                    </a>

                    <a href="/" className='social_handle_facebook'>
                        <BsFacebook style={{ fontSize: 18, marginRight: '5px' }} />
                        <p className='social_handle_text'>Facebook</p>
                    </a>
                </div>
            </div>
            <div className='navbar'>
                
                <div className='nav-logo'>
                <div className='toggle-menu' onClick={toggleMenu}>
                    <div className={showMenu ? 'toggle-line line1' : 'toggle-line'}></div>
                    <div className={showMenu ? 'toggle-line line2' : 'toggle-line'}></div>
                    <div className={showMenu ? 'toggle-line line3' : 'toggle-line'}></div>
                </div>
                    <img src={logo} alt='img_logo' className='img_logo'   />
                    <p>HIGH CANVAS</p>
                </div>
                {/* Menu items */}
                <ul className={showMenu ? 'nav-menu active' : 'nav-menu'}>
                    <li onClick={() => { setMenu("home"); closeMenu(); }}>
                        <Link style={{ textDecoration: 'none' }} to='/'>Home</Link>
                        {menu === "home" ? <hr /> : <></>}
                    </li>
                    
                     
                    

<li onClick={() => { setMenu("allproduct"); closeMenu(); }}>
                        <Link style={{ textDecoration: 'none' }} to='/shop'>All Product</Link>
                        {menu === "allproduct" ? <hr /> : <></>}
                    </li>
                    <li onClick={() => { setMenu("contact"); closeMenu(); }}>
                        <Link style={{ textDecoration: 'none' }} to='/contacts'>Contact Us</Link>
                        {menu === "contact" ? <hr /> : <></>}
                    </li>
                    {/* <li onClick={() => { setMenu("blog"); closeMenu(); }}>
                        <Link style={{ textDecoration: 'none' }} to='/blog'>Blog</Link>
                        {menu === "blog" ? <hr /> : <></>}
                    </li> */}
                </ul>
                <div className='nav-login-cart'>
                    {/* {localStorage.getItem('auth-token')
                    ?<button onClick={()=>{localStorage.removeItem('auth-token');window.location.replace('/')}}>Logout</button>
                :<Link to='/login'><button>Login</button></Link>} */}
                    <Link to='/cart'><img src={cart_icon} /></Link>
                    <div className='nav-cart-count'>{getTotalCartItems()}</div>
                </div>
            </div>
        </>
    )
}
